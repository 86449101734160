/* Greens party green */
.party-greens {
  color: #179a58;
}

/* Labor party red */
.party-labor {
  color: #ff0000;
}

/* Australian Liberal party blue */
.party-liberal {
  color: #0047ab;
}

/* independent party grey */
.party-independent {
  color: #2a2a2a;
}


body {
  margin: 0;
  padding: 0;
  touch-action: pan-y, pan-x;
}


h3,
h2,
h1,
p {
  font-family: Helvetica Neue, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, sans-serif;
}

figcaption {
  margin-left: 30px;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

table {
  border-collapse: collapse;
}

th {
  text-align: left;
}

td {
  padding-left: 4px;
  border-left: 1px solid rgb(192, 192, 192);
}
tr {
  border-bottom: 1px solid rgb(192, 192, 192);
}

td:first-child,
th:first-child {
  border-left: none;
  width: 110px;
}
tr:last-child {
  border-bottom: none;
}
table {
  margin-bottom: 0px;
}

button {
  padding: 0.6em 1.45em;
}

p {
  margin-top: 0px;
}

h2, h3 {
  margin-bottom: 5px;
}

:root {
  --unit-100vh: 100vh;
}
@supports (height: 100dvh) {
  :root {
      --unit-100vh: 100dvh;
  }
}
.hundred-vh-height {
  /* Use CSS var */
  height: var(--unit-100vh);
}



